import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GaiminGgComponent } from './pages/gaimin-gg/gaimin-gg.component';

const routes: Routes = [
  { path: '', component: GaiminGgComponent },
  {
    path: 'testers',
    loadChildren: () => import('./pages/gaimin-testers/gaimin-testers.module').then(m => m.GaiminTestersModule)
  },
  {
    path: 'withdrawal',
    loadChildren: () => import('./pages/withdrawal-confirmation/withdrawal-confirmation.module').then(m => m.WithdrawalConfirmationModule)
  },
  {
    path: 'nft/validation',
    loadChildren: () => import('./pages/nft-validation/nft-validation.module').then(m => m.NftValidationModule)
  },
  {
    path: 'help/antivirus',
    loadChildren: () => import('./pages/help-antivirus/help-antivirus.module').then(m => m.HelpAntivirusModule)
  },
  {
    path: 'success',
    loadChildren: () => import('./pages/success-page/success.module').then(m => m.SuccessModule)
  },
  {
    path: 'failed',
    loadChildren: () => import('./pages/failed-page/failed.module').then(m => m.FailedModule)
  },
  {
    path: 'delete_my_account',
    loadChildren: () => import('./pages/delete-account/delete-account.module').then(m => m.DeleteAccountModule)
  },
  {
    path: 'solana-login',
    loadChildren: () => import('./pages/solana-wallet-login/solana-wallet-login.module').then(m => m.SolanaWalletLoginModule)
  },
  {
    path: 'solana-transaction',
    loadChildren: () => import('./pages/solana-transaction/solana-transaction.module').then(m => m.SolanaTransactionModule)
  },
  {
    path: 'ethereum/genesis',
    loadChildren: () => import('./pages/ethereum-genesis/ethereum-genesis.module').then(m => m.EthereumGenesisModule)
  },
  {
    path: 'solana-claim',
    loadChildren: () => import('./pages/solana-claim-nft/solana-claim-nft.module').then(m => m.SolanaClaimNftModule)
  },
  {
    path: 'solana/pfp/stake',
    loadChildren: () => import('./pages/solana-pfp-nft/solana-pfp-nft.module').then(m => m.SolanaPfpNftModule)
  },
  {
    path: 'solana-rewards-info',
    loadChildren: () => import('./pages/solana-rewards-info/solana-rewards-info.module').then(m => m.SolanaRewardsInfoModule)
  },
  {
    path: 'captcha',
    loadChildren: () => import('./pages/captcha-page/captcha.module').then(m => m.CaptchaModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
