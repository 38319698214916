import { PublicKey } from '@solana/web3.js';
import { sepolia } from '@wagmi/core';
import { publicKey } from '@metaplex-foundation/umi';

export const environment = {
  type: 'qa',
  authModuleUrl: 'https://qa.auth.gaimin.io',
  authModuleApi: 'https://api.qa.auth.gaimin.io/api',
  gaiminApi: 'https://api.qa.gaimin.gg/api',
  downloadPhantomChrome: 'https://chromewebstore.google.com/detail/phantom/bfnaelmomeimhlpmgjnjophhpkkoljpa',
  downloadPhantomFireFox: 'https://addons.mozilla.org/en-US/firefox/addon/phantom-app',
  solana: {
    solanaRPC: 'https://devnet.helius-rpc.com/?api-key=9a531947-27b1-4cd9-9a60-d7c957a0ee5d',
    solanaCluster: 'devnet',
    MPL_ACCOUNT_AUTH: {
      pubkey: new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s'),
      isSigner: false,
      isWritable: false
    },
    MPL_ACCOUNT: {
      pubkey: new PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s'),
      isSigner: false,
      isWritable: false
    },
    creator: publicKey('6283kwQGeCA5C6sxNn4TWtVq3GKrZ22CGSZEtaw8YNkk')
  },
  genesis: {
    chain: sepolia,
    smartContracts: {
      nfts: '0xD8C79fE5613912DD8F692F921980f62689ca4a41',
      erc721: '0xd290f258cAad1a7fAD5113D8EFCE83DC6fA9604A',
      claim: '0x86d0B364c4eB230d8Ec082C2910f1ebD51c90452'
    }
  },
  cloudflareTurnstileSiteKey: '0x4AAAAAAAc7oIofIG7YjF3V'
};


